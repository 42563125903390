import { useEffect, useRef } from 'react';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { Loading } from '@felixcolaci/cic-profile-page-components';

import { Home } from '../routes/home';

import { useConfigStore } from '../stores/config.store';
import { useProfileStore } from '../stores/profile.store';

export function App() {
  const { isLoading, error, user } = useAuth0();
  const initProfile = useProfileStore((state) => state.fetchProfileAsync);
  const initConfig = useConfigStore((state) => state.refreshFromServerAsync);

  const initExecuted = useRef<boolean>(false);

  useEffect(() => {
    if (!initExecuted.current && !isLoading && !error) {
      initExecuted.current = true;

      initConfig().catch(console.error);

      if (user?.sub) {
        initProfile(user.sub).catch(console.error);
      }
    }
  }, [isLoading, error, initProfile, initConfig, user]);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}
