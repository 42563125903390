import type { PlacableComponentProperties } from '../types';

import { CallToAction } from '../callToAction';
import { Heading } from '../heading';
import { Layout } from '../layout';
import { Primitive } from '../primitive';
import { Select } from '../select';

interface ComponentHostProps {
  component: PlacableComponentProperties;
}

export function ComponentHost({ component }: ComponentHostProps) {
  switch (component.type) {
    case 'CALL_TO_ACTION':
      return <CallToAction {...component} />;
    case 'HEADING':
      return <Heading {...component} />;
    case 'PRIMITIVE':
      return <Primitive {...component} />;
    case 'LAYOUT':
      return <Layout {...component} />;
    case 'SELECT':
      return <Select {...component} />;
    default:
      return null;
  }
}
