import { withAuthenticationRequired } from '@auth0/auth0-react';
import type { FormEvent } from 'react';
import Form from 'react-bootstrap/Form';

import { View } from './view';

import { useConfigStore } from '../../stores/config.store';
import { useProfileStore } from '../../stores/profile.store';

function HomeComponent() {
  const component = useConfigStore((state) => state.config);

  const updateProfilePropertyAsync = useProfileStore((state) => state.updateProfilePropertyAsync);

  async function handleSubmitAsync(e: FormEvent<HTMLFormElement>) {
    debugger;
    e.preventDefault();

    const data = Array.from<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>(
      e.currentTarget.querySelectorAll('input, select')
    ).reduce((data, element) => {
      if (!element.disabled && element.name) {
        if (element.type === 'checkbox') {
          // TODO: Update this to be a better solution since Typescript complains about element.checked
          //@ts-ignore
          data[element.name] = element.checked ? 'on' : 'off';
        } else {
          data[element.name] = element.value;
        }
      }
      return data;
    }, {} as Record<string, string>);

    updateProfilePropertyAsync(data);
  }

  return (
    <Form onSubmit={handleSubmitAsync}>
      <View component={component} />
    </Form>
  );
}

export const Home = withAuthenticationRequired(HomeComponent);

Home.displayName = 'Home';
