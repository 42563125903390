import { nanoid } from 'nanoid';
import type { PropsWithChildren } from 'react';
import Form from 'react-bootstrap/Form';

import type { PlacableComponent } from '../types';

import styles from '../styles/components.module.scss';

export const PrimitiveComponentTypes = [
  'boolean',
  'email',
  'string',
  'tel',
  'text',
  'url',
  'number',
  'date'
] as const;

export type PrimitiveComponentType = typeof PrimitiveComponentTypes[number];

type PrimitiveType<Value = never> =
  | {
      type: 'boolean';
      value: boolean | Value;
    }
  | {
      type: 'email' | 'string' | 'tel' | 'text' | 'url';
      value: string | Value;
    }
  | {
      type: 'date';
      value: string | Value;
    }
  | {
      type: 'number';
      value: number | Value;
    };

export interface PrimitiveProps<Value = never> extends PlacableComponent {
  description?: string;
  disabled?: boolean;
  label: string;
  name?: string;
  primitive: PrimitiveType<Value>;
  readOnly: boolean;
  type: 'PRIMITIVE';
}

export function Primitive({
  description,
  disabled,
  id,
  label,
  name,
  primitive,
  readOnly
}: PrimitiveProps) {
  switch (primitive.type) {
    case 'boolean':
      return (
        <Form.Group className={styles.formGroup}>
          <Form.Check
            defaultChecked={primitive.value}
            disabled={disabled || readOnly}
            label={label}
            id={id}
            name={name}
            type="switch"
          />
          <Form.Text>{description}</Form.Text>
        </Form.Group>
      );

    case 'date':
    case 'email':
    case 'number':
    case 'string':
    case 'tel':
    case 'text':
    case 'url': {
      let value = primitive.value;
      if (primitive.type === 'date') {
        const date = new Date(primitive.value);
        const month = date.getMonth() + 1;
        const day = date.getDate() + 1;
        value = `${date.getFullYear()}-${month < 10 ? `0${month}` : month}-${
          day < 10 ? `0${day}` : day
        }`;
      }

      if (primitive.type === 'number' && isNaN(Number(value))) {
        value = '';
      }

      return (
        <PrimitiveForm description={description} label={label} readOnly={readOnly}>
          <Form.Control
            as={primitive.type === 'text' ? 'textarea' : undefined}
            defaultValue={value}
            disabled={disabled || readOnly}
            name={name}
            type={primitive.type === 'string' ? 'text' : primitive.type}
          />
        </PrimitiveForm>
      );
    }

    default:
      return null;
  }
}

interface PrimitiveFormProps {
  description: string | undefined;
  label: string;
  readOnly: boolean;
}

function PrimitiveForm({
  children,
  description,
  label,
  readOnly
}: PropsWithChildren<PrimitiveFormProps>) {
  return (
    <Form.Group className={styles.formGroup}>
      <Form.Label className={styles.formLabel}>
        {label}
        {readOnly && <small className="text-muted">Read only</small>}
      </Form.Label>
      {children}
      {description && <Form.Text>{description}</Form.Text>}
    </Form.Group>
  );
}

export function isPrimitiveType(probe: any): probe is PrimitiveComponentType {
  return PrimitiveComponentTypes.includes(probe);
}

export function primitivePropsFactory(type: PrimitiveComponentType): PrimitiveProps {
  return {
    id: nanoid(),
    type: 'PRIMITIVE',
    label: 'New Primititve Component',
    primitive: {
      type,
      value: getPrimitiveDefaultType(type)
    } as PrimitiveType,
    readOnly: false
  };
}

export function getPrimitiveDefaultType(fieldType: PrimitiveComponentType) {
  switch (fieldType) {
    case 'boolean': {
      return false;
    }
    default: {
      return '';
    }
  }
}
