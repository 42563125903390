import { nanoid } from 'nanoid';
import type { MouseEventHandler } from 'react';
import Button from 'react-bootstrap/Button';

import type { PlacableComponent } from '../types';

export const CallToActionButtonTypes = ['button', 'submit', 'reset'] as const;

export type CallToActionButtonType = typeof CallToActionButtonTypes[number];

export const CallToActionVariantTypes = [
  'primary',
  'secondary',
  'success',
  'warning',
  'danger',
  'info',
  'light',
  'dark',
  'link',
  'outline-primary',
  'outline-secondary',
  'outline-success',
  'outline-warning',
  'outline-danger',
  'outline-info',
  'outline-light',
  'outline-dark'
] as const;

export type CallToActionVariantType = typeof CallToActionVariantTypes[number];

export interface CallToActionProps extends PlacableComponent {
  buttonType: CallToActionButtonType;
  name?: string;
  text: string;
  type: 'CALL_TO_ACTION';
  variant: CallToActionVariantType;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export function CallToAction({ buttonType, name, text, variant, onClick }: CallToActionProps) {
  return (
    <Button type={buttonType} name={name} variant={variant} onClick={onClick}>
      {text}
    </Button>
  );
}

export function callToActionPropsFactory(): CallToActionProps {
  return {
    buttonType: 'submit',
    id: nanoid(10),
    text: 'Call To Action',
    type: 'CALL_TO_ACTION',
    variant: 'primary'
  };
}
