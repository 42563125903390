import type { EditableComponentProperties } from '@felixcolaci/cic-profile-page-components';
import {
  ComponentHost,
  Layout,
  isProfileProperty,
  transformEditableToPlacable
} from '@felixcolaci/cic-profile-page-components';
import { useMemo } from 'react';

import { useProfileStore } from '../../../stores/profile.store';

interface ViewProps {
  component: EditableComponentProperties;
}

export function View({ component }: ViewProps) {
  const { profile, profileSchema } = useProfileStore(({ profile, profileSchema }) => ({
    profile,
    profileSchema
  }));

  const viewComponent = useMemo<EditableComponentProperties>(() => {
    if (
      (component.type === 'PRIMITIVE' || component.type === 'SELECT') &&
      profile &&
      profileSchema
    ) {
      if (component.type === 'PRIMITIVE' && isProfileProperty(component.primitive.value)) {
        component.disabled = false;
        component.name = component.primitive.value.userProperty;
      } else if (component.type === 'SELECT' && isProfileProperty(component.value)) {
        component.disabled = false;
        component.name = component.value.userProperty;
      } else {
        component.disabled = true;
      }
    }

    return component;
  }, [component, profile, profileSchema]);

  if (component.type === 'LAYOUT') {
    return (
      <Layout
        {...component}
        content={
          <>
            {component.content?.map((component) => (
              <View key={component.id} component={component} />
            ))}
          </>
        }
      />
    );
  }

  if (profile && profileSchema && viewComponent.type !== 'LAYOUT') {
    return (
      <ComponentHost
        component={transformEditableToPlacable(viewComponent, profile, profileSchema)}
      />
    );
  }

  return null;
}
