import _ from 'lodash';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

import { PROFILE_SCHEMA_API, USER_API } from '@felixcolaci/cic-profile-page-components';

interface ProfileStore {
  profile: Record<string, unknown> | undefined;
  profileSchema: Record<string, string> | undefined;
  fetchProfileAsync(subject: string): Promise<void>;
  updateProfilePropertyAsync(content: Record<string, unknown>): void;
}

export const useProfileStore = create<ProfileStore>()(
  devtools((set, get) => ({
    profile: undefined,
    profileSchema: undefined,
    async fetchProfileAsync(subject: string) {
      if (subject) {
        const [profile, profileSchema] = await Promise.allSettled<
          [Promise<Record<string, unknown>>, Promise<Record<string, string>>]
        >([
          fetch(`${USER_API}/users/${subject}`)
            .then((data) => data.json())
            .catch(console.error),
          fetch(PROFILE_SCHEMA_API)
            .then((data) => data.json())
            .catch(console.error)
        ]);

        if (profile.status === 'fulfilled') {
          set(() => ({ profile: profile.value }));
        }

        if (profileSchema.status === 'fulfilled') {
          set(() => ({ profileSchema: profileSchema.value }));
        }
      }
    },
    async updateProfilePropertyAsync(content: Record<string, string>) {
      const { profile, profileSchema, fetchProfileAsync } = get();
      if (profile && profileSchema && typeof profile.user_id === 'string') {
        const profileUpdates = {};
        Object.entries(content).forEach(([property, value]) => {
          if (profileSchema[property].includes('boolean')) {
            _.set(profileUpdates, property, value === 'on');
          } else if (profileSchema[property].includes('number')) {
            _.set(profileUpdates, property, Number(value));
          } else {
            _.set(profileUpdates, property, value);
          }
        });

        // Optimistically update the user's profile
        set(() => ({ profile: { ...profile, ...profileUpdates } }));

        const body = JSON.stringify(profileUpdates);

        fetch(`${USER_API}/users/${profile.user_id}`, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          body
        }).then(
          () =>
            profile.user_id &&
            typeof profile.user_id === 'string' &&
            fetchProfileAsync(profile.user_id)
        );
      }
    }
  }))
);
