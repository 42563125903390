import type { CSSProperties, ReactNode } from 'react';
import { nanoid } from 'nanoid';

import type { EditableComponentProperties, PaddingProperty, PlacableComponent } from '../types';

export const DirectionTypes = ['row', 'row-reverse', 'column', 'column-reverse'] as const;
export type DirectionType = typeof DirectionTypes[number];

export const AlignItemsTypes = ['flex-start', 'flex-end', 'center', 'baseline', 'stretch'] as const;
export type AlignItemsType = typeof AlignItemsTypes[number];

export const JustifyContentTypes = [
  'flex-start',
  'flex-end',
  'center',
  'space-between',
  'space-around',
  'space-evenly'
] as const;
export type JustifyContentType = typeof JustifyContentTypes[number];

export interface LayoutProps<ContentType = ReactNode> extends PlacableComponent {
  alignItems: AlignItemsType;
  content: ContentType;
  direction: DirectionType;
  gap: string;
  height: string;
  justifyContent: JustifyContentType;
  padding?: PaddingProperty;
  type: 'LAYOUT';
  width: string;
  style?: CSSProperties;
}

export function layoutPropsFactory(): LayoutProps<Array<EditableComponentProperties>> {
  return {
    alignItems: 'stretch',
    content: [],
    direction: 'row',
    gap: '1rem',
    height: '100%',
    id: nanoid(10),
    justifyContent: 'flex-start',
    padding: {
      top: 16,
      right: 16,
      bottom: 16,
      left: 16
    },
    type: 'LAYOUT',
    width: '100%'
  };
}

export function Layout({
  alignItems,
  content,
  direction,
  gap,
  height,
  id,
  justifyContent,
  padding,
  width,
  style
}: LayoutProps) {
  return (
    <div
      id={id}
      style={{
        alignItems,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: direction,
        flexWrap: 'wrap',
        gap,
        height,
        justifyContent,
        paddingTop: padding?.top,
        paddingRight: padding?.right,
        paddingBottom: padding?.bottom,
        paddingLeft: padding?.left,
        position: 'relative',
        width,
        ...style
      }}>
      {content}
    </div>
  );
}
