import create from 'zustand';
import { devtools } from 'zustand/middleware';

import type {
  AlignItemsType,
  DirectionType,
  EditableComponentProperties,
  JustifyContentType,
  LayoutProps
} from '@felixcolaci/cic-profile-page-components';
import { nanoid } from 'nanoid';

interface ConfigState {
  config: LayoutProps<Array<EditableComponentProperties>>;
  refreshFromServerAsync(): Promise<void>;
}

const CONFIG_URL = 'https://ko3t4vcvqb.execute-api.us-east-1.amazonaws.com/config';

export const useConfigStore = create<ConfigState>()(
  devtools((set) => ({
    config: {
      alignItems: 'stretch' as AlignItemsType,
      content: [] as Array<EditableComponentProperties>,
      direction: 'row' as DirectionType,
      height: '100%',
      id: nanoid(10),
      justifyContent: 'center' as JustifyContentType,
      gap: '0',
      type: 'LAYOUT',
      width: '100%'
    },
    async refreshFromServerAsync() {
      fetch(CONFIG_URL)
        .then((response) => response.json())
        .then((config) => {
          set({ config });
        })
        .catch(console.error);
    }
  }))
);
